import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import Img from 'react-cloudinary-lazy-image'

const SwiperJS = (props) => {

    // const [swiper, setSwiper] = useState(null);
    

   const { swiperFields, naviguable } = props;

   SwiperCore.use([Navigation, Pagination, Autoplay ]);

    const swiperStyle = {
      height: "100%",
      width: "100%",
      position: "relative",
      zIndex: 1
    }

      return (
        <Swiper 
        navigation
        pagination={{
          clickable: true
        }}
        autoplay={{
          delay: 5000,
        }}
        slidesPerView={1}
        spaceBetween={30}
        style={swiperStyle}  >
        {
          /*
            let imgFile, imgFolder, imgVersion;

            if (photos?.length) {
              const photo = photos[0]
              var imgSegment = photo.split('/');
              imgFile = imgSegment.pop();
              imgVersion = imgSegment.pop();
              imgFolder = 'projets';
            } else {
              imgFile = 'no-image.png';
              imgFolder = '';
              imgVersion = '';
            }
*/
        swiperFields.map((el, index) => {
          
          var imgSegment = el.photo.split('/');
          var imgFile = imgSegment.pop();
          var imgFolder = imgSegment.pop();

          return(
            el.categorie && <SwiperSlide key={index}>
            <div className="d-inline-block w-100 category-project-header">
              <h2 className="mx-4 mt-1"> <b style={{ color: '#494949' }}>\ </b><a href={'/projets/' + el.categorie.url} className="projet-categorie-label">{el.categorie.nom?.toUpperCase()}</a>
                <b style={{ color: '#c80000', paddingLeft: '10px' }}>\</b> <a href={"/projets/" + el.categorie.url + "/" + el.url} className="projet-nom-label">{el.nom}</a>
              </h2>
            </div>
            <a href={(naviguable ? ("/projets/" + el.categorie.url + "/" + el.url) : null)}>
              <Img
                cloudName={'groupea'}
                imageName={'projets/' + imgFolder + '/' + imgFile}
                fluid={{
                  maxWidth: 1920
                }}
                style={{
                  width: '100vw',
                  height: '65vh'
              }}
                alt={imgFile}
                quality={false}
              />
              </a>
            </SwiperSlide>)
        })
        }
      </Swiper> 
      );
}

export default SwiperJS;